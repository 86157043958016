import React from "react";
import Layout from "../../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";

const BlogPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}

				heroImg: file(relativePath: { eq: "jason-client-meeting-5.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 90) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				allWpPost(sort: { fields: dateGmt, order: DESC }) {
					nodes {
						id
						title
						excerpt
						slug
						image {
							featuredImage {
								localFile {
									childImageSharp {
										gatsbyImageData(
											placeholder: TRACED_SVG
											formats: [AUTO, WEBP]
											transformOptions: { fit: COVER }
											quality: 100
										)
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const ogImg = `${siteUrl}${schemaImage}`;

	return (
		<Layout>
			<GatsbySeo
				title="My Business Coach Blog"
				description="Jason Cornes Business Coach Blog"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/blog`,
					title: "My Business Coach Blog",
					description: "Jason Cornes Business Coach Blog",
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Jason Cornes Business Coach Blog",
						},
					],
				}}
			/>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h1 className="text-center mb-5">Blog posts</h1>
						</Col>
					</Row>
					<Row>
						{data.allWpPost.nodes.map((blog) => (
							<Col key={blog.id} xs={12} md={6} lg={4} className="mb-3">
								<Card className="h-100">
									<Link to={`/blog/${blog.slug}`}>
										<Card.Img
											as={GatsbyImage}
											image={
												blog.image?.featuredImage?.localFile.childImageSharp
													.gatsbyImageData
											}
										></Card.Img>
									</Link>
									<Card.Title
										className="px-3"
										style={{ paddingLeft: "1.25rem", paddingTop: "1.25rem" }}
									>
										{blog.title}
									</Card.Title>
									<Card.Body className="px-3">
										<div className="blog-excerpt">{parse(blog.excerpt)}</div>
									</Card.Body>
									<Card.Footer className="border-0 bg-white">
										<Link className="text-primary" to={`/blog/${blog.slug}`}>
											Read More
										</Link>
									</Card.Footer>
								</Card>
							</Col>
						))}
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default BlogPage;
